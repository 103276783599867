import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import CaseStudyArea from "../components/CaseStudyArea";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const CaseStudyDetails = () => {
  return (
    <>
      <Navbar />
      <Breadcrumb title={"Case Study Details"} />
      <CaseStudyArea />
      <Footer />
    </>
  );
};

export default CaseStudyDetails;
