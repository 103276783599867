import React from "react";
import AboutArea from "../components/AboutArea";
import Breadcrumb from "../components/Breadcrumb";
import ContactArea from "../components/ContactArea";
import CounterArea from "../components/CounterArea";
import FaqArea from "../components/FaqArea";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TeamArea from "../components/TeamArea";
import WorkProcess from "../components/WorkProcess";

const About = () => {
  return (
    <>
      <Navbar />
      <Breadcrumb title={"About Us"} />
      <AboutArea />
      <FaqArea />
      <TeamArea />
      <CounterArea />
      <ContactArea />
      <WorkProcess />
      <Footer />
    </>
  );
};

export default About;
