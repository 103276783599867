import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <nav className='navbar navbar-area navbar-area-2 navbar-expand-lg'>
      <div className='container nav-container'>
        <div className='responsive-mobile-menu'>
          <button
            onClick={menuActive}
            className={
              active
                ? "menu toggle-btn d-block d-lg-none open"
                : "menu toggle-btn d-block d-lg-none"
            }
            data-target='#itech_main_menu'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='icon-left' />
            <span className='icon-right' />
          </button>
        </div>
        <div className='logo'>
          <Link to='/'>
            <img src='assets/img/logo.png' alt='img' />
          </Link>
        </div>
        <div
          className={
            active
              ? "collapse navbar-collapse sopen"
              : "collapse navbar-collapse"
          }
          id='itech_main_menu'
        >
          <ul className='navbar-nav menu-open text-lg-end'>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/service'>Service</Link>
            </li>
            <li className='menu-item-has-children'>
              <a href='#'>Pages</a>
              <ul className='sub-menu'>
                <li>
                  <Link to='/about'>About Us</Link>
                </li>
                <li>
                  <Link to='/team'>Team</Link>
                </li>
                <li>
                  <Link to='/team-details'>Team Details</Link>
                </li>
                <li>
                  <Link to='/case-study-details'>Case Study Details</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to='/contact'>Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className='nav-right-part nav-right-part-desktop align-self-center'>
          <Link className='btn btn-border-base' to='/about'>
            Discover More <FaPlus />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
