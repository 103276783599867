import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";  
import Navbar from "../components/Navbar";
import ServiceDetailsArea from "../components/ServiceDetailsArea";

const ServiceDetails = () => {
  return (
    <>
      <Navbar />
      <Breadcrumb title={"Service Details"} />
      <ServiceDetailsArea />
      <Footer />
    </>
  );
};

export default ServiceDetails;
