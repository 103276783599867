import React from "react";
import AboutArea from "../components/AboutArea";
import Banner from "../components/Banner";
import ContactArea from "../components/ContactArea";
import CounterArea from "../components/CounterArea";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PricingArea from "../components/PricingArea";
import ServiceArea from "../components/ServiceArea";
import Testimonial from "../components/Testimonial";
import WorkProcess from "../components/WorkProcess";

const Home = () => {
  return (
    <>
      <Navbar />
      <Banner />
      <AboutArea />
      <ServiceArea />
      <PricingArea />
      <CounterArea />
      <ContactArea />
      <WorkProcess />
      <Testimonial />
      <Footer />
    </>
  );
};

export default Home;
