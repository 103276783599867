import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ContactMain from "../components/ContactMain";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Contact = () => {
  return (
    <>
      <Navbar />
      <Breadcrumb title={"Contact"} />
      <ContactMain />
      <Footer />
    </>
  );
};

export default Contact;
