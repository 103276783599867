import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TeamAreaGroup from "../components/TeamAreaGroup";

const Team = () => {
  return (
    <>
      <Navbar />
      <Breadcrumb title={"Team"} />
      <TeamAreaGroup />
      <Footer />
    </>
  );
};

export default Team;
